<main class="w-full h-screen flex justify-between gap-4 font-body overflow-hidden bg-soft-blue dark:bg-gradient-to-br dark:from-slate-900 dark:to-slate-950">
  <div class="flex justify-between z-50 sticky top-0 bottom-0">
    <router-outlet name="sidebar"></router-outlet>
  </div>

  <section cdkScrollable class="flex-1 flex flex-col justify-between relative overflow-y-auto p-8 xl:px-16">
    <header class="pb-4">
      <app-main-header></app-main-header>
    </header>
    <div class="flex-1 flex flex-col justify-between rounded-lg"
      [ngClass]="{
        'border shadow-sm dark:bg-soft-blue fade-in-up bg-white': userOrganisation
        }">
      <div class="top-0 z-40" [ngClass]="{'sticky': sticky}">
        <router-outlet name="breadcrumbs"></router-outlet>
      </div>
      <div class="grow" [ngClass]="{
        'p-6': userOrganisation,
      }">
        <router-outlet></router-outlet>
      </div>
    </div>
  </section>
</main>
